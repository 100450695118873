<template>

  <div v-if="isShow">
    <Loading/>
  </div>

  <div class="page-banner" :style="{ 'background-image': 'url(' + banner + ')' }">
    <div class="overlay">
      <div class="trapezoidal-block">
        <h1>{{ $t("product") }}</h1>
        <div class="small-nav">
          <router-link to="/">
            {{ $t("home") }}
          </router-link>
          <span>丨</span>
          <router-link to="/products">
            {{ $t("product") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="minHeight middle common-padding-100">
    <div class="product">
      <div class="pro-list">
        <ul>
          <li class="common-item"
              v-for="item in list"
              :key="item.id"
              @click="details(item.id, item.title)"
          >
            <div class="common-item-img">
              <img :src="item.image">
            </div>

            <div class="common-item-txt">
              <h3>{{ item.title }}</h3>
              <p>{{item.subheading}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <FooterInquiry></FooterInquiry>
</template>

<script>
import { ref } from 'vue'
import FooterInquiry from "@/components/FooterInquiry";
import Loading from "@/components/loading";
import {productCategories} from "@/network/main";

export default {
  name: "Products",
  components:{
    FooterInquiry,
    Loading
  },
  setup() {
    const meta = ref({
      title: "",
      keywords: "",
      description: "",
      baiduSiteVerification : ""
    });
    return {
      meta
    }
  },
  metaInfo () {
    return {
      title:"Products - OSONG ELECTRIC Co. LTD",
      meta: [
        {
          name: 'description',
          content: this.seo
        }
      ],
    }
  },
  data(){
    return{
      isShow:true,
      banner:'',
      list:[],
      seo:'',
    }
  },
  created() {
    this.getInfo()
  },
  methods:{
    getInfo() {
      productCategories().then(res =>{
        this.isShow = false;
        this.banner = res.data.data.banner;
        this.list = res.data.data.list.list;
        this.seo = res.data.data.seo;
      })
    },
    //详情----details(id, title)同时传递id和titleProductsList
    details(id, title) {
      this.$router.push({ name: 'ProductsList', params: { id: id, title: title } });
    }
  }
}
</script>

<style scoped>

</style>